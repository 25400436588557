import React from "react";
import Hero from "./components/Hero";
import SectionEnfoque from "./components/SectionEnfoque";
import SectionAgenda from "./components/SectionAgenda";
import SectionPacientesNo from "./components/SectionPacientesNo";
import SectionMaterial from "./components/SectionMaterial";
import SectionPreguntas from "./components/SectionPreguntas";
import Testimonios from "./components/Testimonios";
import Footer from "./components/Footer";
import { Toast } from "tools";

function HomePage() {
  return (
    <div>
      <Toast></Toast>
      <Hero></Hero>
      <SectionEnfoque></SectionEnfoque>
      <Testimonios></Testimonios>
      <SectionAgenda></SectionAgenda>
      <SectionPacientesNo></SectionPacientesNo>
      <SectionMaterial></SectionMaterial>
      <SectionPreguntas></SectionPreguntas>
      <Footer></Footer>
    </div>
  );
}

export default HomePage;
