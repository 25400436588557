import { AuthContext } from "api/AuthContextProvider";
import { useContext, useState } from "react";
import { Button, Form, Modal, Spinner, Tab, Tabs } from "react-bootstrap";

import { es } from "date-fns/locale";
import moment from "moment";
import { DayPicker } from "react-day-picker";
import "react-day-picker/dist/style.css";

import { getlist_opciones } from "api";
import { tfail, tsuccess } from "tools";
import { crearUrlFlow, crear_reserva } from "services/agendaService";

function ModalAgendar({ tipo: tipoReserva }) {
  const { isAuthenticated, logout } = useContext(AuthContext);
  const [isLoading, setIsLoading] = useState(false);

  // Hooks de estado para la visibilidad del modal y el estado de reserva
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  // Función para ocultar los fines de semana en el calendario
  function disableDays(day) {
    // no permitir ver los martes, jueves y domingos, ni mañana
    let dias = [0, 2, 4, new Date().getDay() + 1];
    return dias.includes(day.getDay());
    //return day.getDay() === 0 || day.getDay() === 6;
  }

  // Array de días deshabilitados y ocultos para el calendario
  const disabledDays = [
    { from: new Date(2020, 4, 18), to: new Date() },
    disableDays,
  ];
  const hiddenDays = [];

  const [selectedDay, setSelectedDay] = useState(false);
  const handleClickAgendar = (day, modifiers) => {
    setSelectedDay(day);
    setSelectedHora(0);
    setLoadingHoras(true);

    // obtener horas disponibles
    getlist_opciones(day).then((data) => {
      // data = ["09:00", "10:30", "12:00", "15:00", "16:30", "18:00"]

      // si el dia es sábado, no permitir opciones después de las 13:00
      if (day.getDay() === 6) {
        data = data.filter((v) => {
          let hora = moment(v, "HH:mm");
          return hora.hours() < 13;
        });
      }

      setLoadingHoras(false);
      const opciones = data; // todo: procesar datos
      // Establecer los horarios disponibles en el estado
      setOpcionesHoras(opciones);
    });
  };

  // Hook de estado para los horarios disponibles
  const [opcionesHoras, setOpcionesHoras] = useState([]);

  // Hook de estado para el horario seleccionado
  const [selectedHora, setSelectedHora] = useState(0);
  const handleSelectChange = (e) => {
    setSelectedHora(e.target.value);
  };

  // Función para confirmar la reserva
  function seleccionarFecha() {
    // Validar que se haya seleccionado un horario
    if (selectedHora !== 0 && selectedDay) {
      // Verificar si el horario seleccionado está disponible
      const horarioDisponible = opcionesHoras.includes(selectedHora);
      if (horarioDisponible) {
        setConfirmarDisabled(false);
        setKeyTab("confirmar");
      } else {
        alert(
          "El horario seleccionado ya está reservado. Por favor, seleccione otro horario."
        );
      }
    } else {
      alert("Por favor, seleccione un horario.");
    }
  }

  const confirmarReserva = async () => {
    try {
      const hora = moment(selectedHora, "HH:mm");
      // Sumamos la hora al objeto moment original
      const fecha = moment(selectedDay)
        .clone()
        .add(hora.hours(), "hours")
        .add(hora.minutes(), "minutes");
      const data = {
        fecha: fecha.unix(),
        fechaLocal: fecha.local().format("YYYY-MM-DD HH:mm:ss"),
        email: inputEmail,
        nombre: inputNombre,
        tipo: tipoReserva,
      };

      // obtener id de reserva
      const reserva = await crear_reserva(data);
      if (reserva.id) {
        // obtener url de pago
        const response = await crearUrlFlow(reserva.id);

        tsuccess("Redireccionando al sitio de pago...");
        window.location.href = `${response.url}?token=${response.token}`;
        setIsLoading(false);
      }
    } catch (error) {
      console.log(error);
      tfail(
        "Error al crear la orden de pago. Por favor, inténtelo nuevamente más tarde."
      );
      setIsLoading(false);
    }
  };

  const [inputEmail, setInputEmail] = useState("");
  const [inputNombre, setInputNombre] = useState("");

  const [showLogin, setShowLogin] = useState(false);
  const [showSignup, setShowSignup] = useState(false);

  const [keyTab, setKeyTab] = useState("home");
  const [isLoadingHoras, setLoadingHoras] = useState(false);
  const [confirmarDisabled, setConfirmarDisabled] = useState(true);

  const handleSubmit = (e) => {
    e.preventDefault();
    setIsLoading(true);

    // validacion
    if (inputEmail === "") {
      tfail("Por favor, ingrese su correo electrónico.");
      setIsLoading(false);
      return;
    } else {
      if (!inputEmail.includes("@")) {
        tfail("Por favor, ingrese un correo electrónico válido.");
        setIsLoading(false);
        return;
      }
    }

    confirmarReserva(tipoReserva);
  };
  return (
    <>
      <Button
        variant="success rounded animate__animated animate__pulse animate__infinite"
        onClick={handleShow}
      >
        Agendar ahora 💜
      </Button>

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Selecciona el día y la hora de tu sesión</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="row">
            <Tabs
              id="controlled-tab-example"
              activeKey={keyTab}
              onSelect={(k) => setKeyTab(k)}
              className="mb-3"
              fill
            >
              <Tab eventKey="home" title="Seleccionar día">
                <div className="row d-flex">
                  <div className="col-12 d-flex justify-content-center">
                    <DayPicker
                      locale={es}
                      onDayClick={handleClickAgendar}
                      //footer={footer}
                      disabled={disabledDays}
                      hidden={hiddenDays}
                      //modifiers={{ booked: bookedDays }}
                      //modifiersStyles={{ selected: { color: "red" } }}
                      mode="single"
                      selected={selectedDay}
                    />
                  </div>
                  <div className="col-12 p-3">
                    <Form.Group className="d-flex">
                      {isLoadingHoras ? (
                        <Spinner
                          animation="border"
                          variant="primary"
                          className="mx-auto"
                        />
                      ) : (
                        <Form.Select
                          value={selectedHora}
                          onChange={handleSelectChange}
                        >
                          <option value={0}>
                            {opcionesHoras.length > 0
                              ? "Seleccione una opción"
                              : "No existen horas disponibles"}
                          </option>
                          {opcionesHoras.map((v, k) => (
                            <option key={k} value={v}>
                              {v}
                            </option>
                          ))}
                        </Form.Select>
                      )}
                    </Form.Group>
                  </div>
                  <div className="col-12 d-flex justify-content-end p-3">
                    <Button
                      variant="primary"
                      onClick={() => seleccionarFecha()}
                    >
                      Siguiente
                    </Button>
                  </div>
                </div>
              </Tab>
              <Tab
                eventKey="confirmar"
                disabled={confirmarDisabled}
                title="Confirmar hora"
              >
                <div className="col-12">
                  <p>Fecha: {moment(selectedDay).format("DD/MM/YYYY")}</p>
                  <p>Horario: {selectedHora}</p>
                </div>
                <div className="col-12 d-flex justify-content-start pt-5">
                  <Form
                    onSubmit={handleSubmit}
                    className="w-100 d-flex flex-column justify-content-center align-items-center"
                  >
                    <Form.Group className="mb-3">
                      <Form.Label>Correo electrónico</Form.Label>
                      <Form.Control
                        type="email"
                        value={inputEmail}
                        placeholder="Ingresa tu correo"
                        onChange={(e) => setInputEmail(e.target.value)}
                      />
                    </Form.Group>
                    <Form.Group className="mb-3">
                      <Form.Label>Nombre</Form.Label>
                      <Form.Control
                        type="text"
                        value={inputNombre}
                        placeholder="Tu nombre"
                        onChange={(e) => setInputNombre(e.target.value)}
                      />
                    </Form.Group>
                    <Button
                      variant="success"
                      disabled={isLoading}
                      type="submit"
                    >
                      {isLoading ? (
                        <>
                          <Spinner animation="border" size="sm" />
                          Cargando
                        </>
                      ) : (
                        "Confirmar hora"
                      )}
                    </Button>
                  </Form>
                </div>
              </Tab>
            </Tabs>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default ModalAgendar;
