import React from "react";
import { Image } from "react-bootstrap";

import camiPerfil from "images/cami_perfil.jpg";
import camiDonas from "images/camiDonas.jpg";

function SectionSobreMi() {
  // foto cami a la derecha, breve biografía abajo; foto manzana a la derecha
  // color cominación con rosado pastel?

  return (
    <div className="container-fluid image-sandia">
      <div className="row d-flex justify-content-end px-xl-4 px-md-3 py-4">
        <div className="col-12 pb-5">
          <h2 className="display-5 fw-bold text-center text-white mb-3">
            Sobre mi
          </h2>
        </div>
        <div className="col-lg-6 pb-3">
          <div className="card">
            <div className="card-body">
              <Image width="600" src={camiDonas} thumbnail />
            </div>
          </div>
        </div>
        <div className="col-lg-6 col-md-10 pb-3">
          <div className="card shadow-lg animate__animated animate__fadeInRight ">
            <div className="card-body ">
              <div className="text-center d-flex flex-column justify-content-center">
                <h2>Mi visión</h2>
                <p>
                  Mi objetivo es ser tu guía personalizada en el camino hacia
                  una alimentación saludable y equilibrada, adaptada a tus
                  necesidades y metas individuales. Juntos, exploraremos los
                  secretos de la nutrición y descubriremos cómo los alimentos
                  pueden mejorar tu salud y bienestar. Te brindaré asesoramiento
                  basado en evidencia y te ayudaré a desarrollar una relación
                  positiva con la comida. Creo en un enfoque de alimentación
                  consciente y sin dietas restrictivas para que puedas lograr un
                  estilo de vida saludable a largo plazo. En "Tu Nutri Amiga",
                  nos importa tu éxito y felicidad. Estoy aquí para ofrecerte el
                  apoyo y la motivación necesarios para alcanzar tus objetivos.
                  Estoy emocionada de embarcarme en este viaje contigo. ¡Únete a
                  mí y comienza tu transformación hacia una vida más saludable
                  hoy mismo!
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="col-12 pb-3">
          <div className="card shadow-lg animate__animated animate__fadeInRight ">
            <div className="card-body">
              <div className="d-flex flex-column justify-content-center">
                <h2 className="text-center mb-3">Mis certificaciones</h2>
                <ul className="list-group list-group-flush text-left">
                  <li className="list-group-item">
                    Diplomado Nutrición Clínica. | Instituto Vive Sano Brasil |
                    Julio 2022 - Julio 2023
                  </li>
                  <li className="list-group-item">
                    Certificación Internacional Nutrición Clínica Oncológica. |
                    Instituto Vive Sano Brasil | 2022 - 2023
                  </li>
                  <li className="list-group-item">
                    Curso Intensivo Soporte Nutricional. | Instituto Vive Sano
                    Brasil | 2022
                  </li>
                  <li className="list-group-item">
                    International course for Health Professionals focused on
                    Nutrition. | Instituto Vive Sano Brasil | 2021 - 2022
                  </li>
                  <li className="list-group-item">
                    Certificación en Introducción a la Medicina del Estilo de
                    Vida. | Universidad de los Andes | 2021 - 2022
                  </li>
                  <li className="list-group-item">
                    Certificación Internacional en Psicología de la
                    Alimentación. | Instituto Vive Sano Brasil | 2020 - 2021
                  </li>
                  <li className="list-group-item">
                    Certificación Ley de Etiquetado. | SERCOTEC | 2020
                  </li>
                  <li className="list-group-item">
                    Curso Nutracéuticos y Fitoterapia. | Universidad de Granada,
                    España | 2017 - 2018
                  </li>
                  <li className="list-group-item">
                    Certificación en Salud escolar y Prevención de la Obesidad.
                    | Universidad de Chile | 2018
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SectionSobreMi;
