import React from "react";
import { Card } from "react-bootstrap";
import { FaStar } from "react-icons/fa";
import { useState } from "react";

function Testimonios() {
  const testimonios = [
    {
      author: "Carmen Daigre Miranda",
      text: "Mi experiencia con Cami ha sido de redescubrimiento, donde se dan los espacios para conversar desde el alma. Sin duda alguna, no es la clásica atención nutricional, donde vas con miedo y pensando qué alimentos me van a restringir ahora, porque no logré la meta del mes. Es un encuentro donde vas aprendiendo a escuchar las necesidades de tu propio cuerpo y desde ahí a disfrutar lo que comes, los olores, sabores, texturas y a entender la alimentación como un acto de gratitud al cuerpo que nos sostiene. No es un camino fácil, ni mucho menos instantáneo, porque se trabaja desde la conciencia del ser y no desde pautas estructuradas de alimentación. Es una experiencia que vale la pena vivirla.",
    },
    {
      author: "",
      text: "Camila me ha ayudado a manejar mis niveles de ansiedad y a controlar atracones.",
    },
    {
      author: "",
      text: "Lo que me gusta es que Cami no juzga, entiende que es un proceso largo y muchas veces difícil. No me siento presionada a seguir objetivos rígidos, entiende que tenemos días y días. Y sobre todo, me gusta que se centra en que tenemos que querernos como somos y no sentirnos culpables por comer. Me motiva a seguir dando pequeños pasos para mejorar mi relación con los alimentos.",
    },
    {
      author: "",
      text: "La nutricionista es genial, además de escuchar cada uno de tus requerimientos, siempre está dispuesta a responder dudas y llevar un seguimiento. Las consultas son un proceso empático, respetuoso y divertido. Los planes de alimentación personalizados me han ayudado a mejorar mi salud y mi bienestar en general.",
    },
    {
      author: "Fernanda Ruz",
      text: "Muy buena nutricionista, te hace sentir acompañada y es súper tiernita, recomendada 100%.",
    },
    {
      author: "Paula Gutiérrez",
      text: "Camila es súper cercana a la hora de explicar, muy buena experiencia.",
    },
    {
      author: "",
      text: "Recién he tenido una primera consulta, espero poder seguir. Es amable y cuando he necesitado orientación, ella ha estado presente.",
    },
  ];

  const [testimonials, setTestimonials] = useState(testimonios);

  // Función para cambiar el estado de expansión de un testimonio.
  const toggleExpand = (index) => {
    const newTestimonials = testimonials.map((testimonial, i) => {
      if (i === index) {
        return { ...testimonial, isExpanded: !testimonial.isExpanded };
      }
      return testimonial;
    });
    setTestimonials(newTestimonials);
  };

  return (
    <section
      id="testimonials"
      className="section image-frutillaFull py-5"
      style={{ backgroundColor: "#E8C7CD" }}
    >
      <div className="container py-5">
        <div className="row">
          <div className="col-12 row">
            {testimonials.map((testimonial, key) => (
              <div className="col-md-6 col-lg-3" key={key}>
                <Card className="mb-3 shadow" style={{ cursor: 'pointer' }} onClick={() => toggleExpand(key)}>
                  <Card.Body>
                    <Card.Text>
                      {testimonial.isExpanded
                        ? testimonial.text
                        : `${testimonial.text.substring(0, 250)}...`}
                    </Card.Text>
                    <Card.Subtitle className="mb-2 text-muted">
                      {testimonial.author}
                    </Card.Subtitle>
                    {testimonial.stars && (
                      <div className="d-flex align-items-center">
                        <div className="me-2">
                          {[...Array(testimonial.stars)].map((star, i) => (
                            <FaStar key={i} />
                          ))}
                        </div>
                        <div>{testimonial.stars} estrellas</div>
                      </div>
                    )}
                  </Card.Body>
                </Card>
              </div>
            ))}
          </div>
        </div>
      </div>
    </section>
  );
}

export default Testimonios;
