import React from "react";
import { Image } from "react-bootstrap";

import camiPerfil from "images/cami_perfil.jpg";

function SectionEnfoque() {
  // foto cami a la derecha, breve biografía abajo; foto manzana a la derecha
  // color cominación con rosado pastel?
  let descripcion = [
    { title: "Consulta amigable 🙂", descripcion: "Empático y respetuoso" },
    { title: "Proceso empático y respetuoso ❤️" },
    {
      title: "No dietas estrictas",
      descripcion: "Sin restricciones alimentarias",
    },
  ];
  return (
    <section id="section_sobremi" className="section-full image-palta">
      <div className="container py-4">
        <div className="row d-flex justify-content-end px-xl-4 px-md-3 py-4">
          <div className="col-12">
            <h2 className="display-5 fw-bold text-center text-white mb-3">
              Nuestro enfoque
            </h2>
          </div>
          {descripcion.map((c, i) => (
            <div key={i} className="col-lg-4 text-center mb-2 mb-md-4">
              <div className="card shadow animate__animated animate__fadeInLeft">
                <div className="card-body">{c.title}</div>
              </div>
            </div>
          ))}

          <div className="col-lg-6 col-md-10">
            <div className="card shadow-lg animate__animated animate__fadeInRight ">
              <div className="card-body ">
                <div className="text-center d-flex flex-column justify-content-center">
                  <div className="">
                    <Image width="200" src={camiPerfil} thumbnail />
                  </div>
                  <h2>Camila Maturana</h2>
                  <p>🎓 UVM 🇨🇱 - UGR 🇪🇸</p>
                  <p>
                    🧠 Nutrición Clínica | Psiconutrición | Oncología. 🍏
                    Alimentación consciente.
                  </p>
                  <p>
                    ¡Hola 🤍! Soy nutricionista de la Universidad Viña del Mar
                    con formación en la Universidad de Granada, España. "Tu
                    nutri amiga" es un espacio para acompañar y guiar el proceso
                    nutricional de manera humana, empática y divertida.
                    ¡Bienvenidos! 🥑💜
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default SectionEnfoque;
