import React from 'react';

import { FaInstagram, FaWhatsapp } from "react-icons/fa";
import { SiGmail } from "react-icons/si";

function Footer() {
  return (
    <footer className="bg-light shadow-sm" id="contact">
      <div className="container">
        <div className="row py-4">
          <div className="col-md-6 col-lg-8">
            <h5>Contáctanos</h5>
            <p className="text-muted">
              Si tienes alguna pregunta, no dudes en contactarnos.
              <a
                href="mailto:camila.tunutriamiga@gmail.com"
                className="d-block"
              >
                <SiGmail size={20} /> camila.tunutriamiga@gmail.com
              </a>
            </p>
          </div>
          <div className="col-md-6 col-lg-4">
            <h5>Síguenos</h5>
            <div className="d-flex justify-content-start">
              <a
                href="https://instagram.com/tunutriamiga_?igshid=YmMyMTA2M2Y="
                className="btn btn-outline-dark btn-social rounded-circle mx-1"
              >
                <FaInstagram size={30} />
              </a>

              <a
                href="https://web.whatsapp.com/send/?phone=56990504908&text=Hola%21+Me+gustar%C3%ADa+m%C3%A1s+informaci%C3%B3n+sobre+las+consultas+nutricionales"
                className="btn btn-outline-dark btn-social rounded-circle mx-1"
              >
                <FaWhatsapp size={30} />
              </a>

              <a
                href="mailto:camila.tunutriamiga@gmail.com"
                className="btn btn-outline-dark btn-social rounded-circle mx-1"
              >
                <SiGmail size={30} />
              </a>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
}

export default Footer;