import React from "react";
import { Button } from "react-bootstrap";
import { FaDownload } from "react-icons/fa";

function SectionMaterial() {
  let descargable = [
    {
      title: "Listado de compras (plantilla)",
      descripcion: "",
      url: "https://drive.google.com/file/d/1k7Ixf5VI-uZt_MAbVcovUbvp07RPRAb4/view?usp=drive_link",
    },
    {
      title: "Menú diario (plantilla)",
      descripcion: "",
      url: "https://drive.google.com/file/d/1BhcV4LCOKfIIaZh5PKP3LSYSNRnLVGt5/view?usp=drive_link",
    },
    {
      title: "Planner semanal (plantilla)",
      descripcion: "",
      url: "https://drive.google.com/file/d/103GrCQONI86D3DvIEIrbzolhWwWIWFoR/view?usp=drive_link",
    },
  ];

  return (
    <section
      id="material"
      className="section-mid image-kiwi"
      style={{ backgroundColor: "#FFDB94" }}
    >
      <div className="container py-4">
        <div className="row gy-4 ">
          <div className="col-12">
            <h2 className="display-5 fw-bold text-center">
              Material descargable
            </h2>
          </div>

          {descargable.map((c, i) => (
            <div key={i} className="col-lg-4 text-center">
              <div className="card shadow">
                <div className="card-body align-items-center">
                  <div className="d-flex justify-content-between align-items-center">
                    <h6 className="fw-bold m-0">{c.title}</h6>
                    <Button
                      onClick={() => (window.location.href = c.url)}
                      key={i}
                      variant="link"
                      className="d-block"
                    >
                      <FaDownload className="text-primary ms-3" />
                    </Button>
                  </div>

                  {c.descripcion ? <p>{c.descripcion}</p> : ""}
                  {c.precio ? <p>{c.precio}</p> : ""}
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
}

export default SectionMaterial;
