import React, { useState, useContext } from "react";
import { Modal, Form, FormControl, Button, Spinner } from "react-bootstrap";
import { AuthContext } from "api/AuthContextProvider";
import { GoogleLogin } from "@react-oauth/google";

function ModalLogin({ show, handleClose, handleSignup }) {
  const [inputEmail, setInputEmail] = useState("");
  const [inputPassword, setInputPassword] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const { login, googleValidateToken } = useContext(AuthContext);

  const handleSubmit = (e) => {
    e.preventDefault();
    setIsLoading(true);
    login(inputEmail, inputPassword)
      .then((response) => {
        setIsLoading(false);
        handleClose();
      })
      .catch((e) => {
        setIsLoading(false);
      });
  };

  const handleModalClose = () => {
    handleClose();
  };

  const handleModalSignup = () => {
    handleSignup();
    handleClose();
  };

  return (
    <>
      <Modal show={show} onHide={handleModalClose}>
        <Modal.Header closeButton>
          <Modal.Title>Iniciar sesión</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={handleSubmit}>
            <Form.Group className="mb-3" controlId="formBasicEmail">
              <Form.Label>Correo electrónico</Form.Label>
              <FormControl
                type="email"
                value={inputEmail}
                placeholder="Ingresa tu correo electrónico"
                onChange={(e) => setInputEmail(e.target.value)}
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="formBasicPassword">
              <Form.Label>Contraseña</Form.Label>
              <FormControl
                type="password"
                value={inputPassword}
                placeholder="Ingresa tu contraseña"
                onChange={(e) => setInputPassword(e.target.value)}
              />
            </Form.Group>
            <Button variant="primary" disabled={isLoading} type="submit">
              {isLoading ? (
                <Spinner animation="border" size="sm" />
              ) : (
                "Iniciar sesión"
              )}
            </Button>
            <Button variant="link" onClick={handleModalSignup}>
              Crea tu cuenta
            </Button>
            <div className="mt-3">
              <GoogleLogin
                onSuccess={(credentialResponse) => {
                  // validar e iniciar sesion
                  googleValidateToken(credentialResponse.credential);
                }}
                onError={() => {
                  console.log("Login Failed");
                }}
              />
            </div>
          </Form>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default ModalLogin;
