import React, { useState, useContext } from "react";
import { Button, Collapse, Modal } from "react-bootstrap";
import { AuthContext } from "api/AuthContextProvider";
import { ModalAgendar, ModalFicha } from "components";

function SectionAgenda() {
  const { isAuthenticated, logout } = useContext(AuthContext);

  const servicios = [
    {
      title: "Consulta online 💜",
      subtitulo:
        "Consulta nutricional en línea a través de videollamada (Google Meet) para objetivos personalizados.",
      descripcion:
        "Esta modalidad de consulta nutricional consiste en comunicamos a través de una videollamada (Google meet) con una duración de 60 min, en dónde te preguntaré sobre tus hábitos alimenticios, gustos , preferencias, cómo es tu relación con la comida, cómo es tu relación con tu cuerpo, si estás enfrentando algún problema de salud entre otros.(Si no tienes exámenes, no te preocupes). Con esta sesión podremos crear objetivos nutricionales alcanzables, sin dietas restrictivas y en base a una nutrición consciente. Durante la sesión, estableceremos en conjunto el tipo de minuta que quieres recibir, también los objetivos a corto, mediano y largo plazo y posterior a 4 días hábiles recibirás al correo registrado en mi página web, toda la planificación alimentaria personalizada con recetario junto con algunos regalos💜. Esta modalidad tiene como finalidad el conocernos desde la comodidad de tu hogar, crear un ambiente cálido, cercano y amigable para que juntos(as) podamos cumplir tus metas y objetivos. ¡Gracias por confiar en mi trabajo!💜🍎",
      tiempo: "60 minutos app.",
      precio: "CLP 30.000",
      precio_descuento: "CLP 22.990",
      activo: true,
      tipo: "paciente_nuevo",
    },
    {
      title: "Asesoría nutricional con formulario en línea 🍎",
      subtitulo:
        "Formulario en línea para una planificación alimentaria personalizada en 4 días.",
      descripcion:
        "Este formulario está diseñado para recopilar información valiosa sobre tus hábitos alimenticios, relación con la comida, gustos, estado de salud, objetivos nutricionales entre otros, ya que mi objetivo es lograr una alimentación saludable y sostenible en el tiempo. Esta modalidad es fácil de usar y se puede completar desde la comodidad de tu hogar. Si eliges este servicio puedes acceder a él en nuestro sitio web y completar con la información requerida en un plazo de 24 hrs. Luego con toda esta información se creará una planificación alimentaria personalizada y adaptada a cada una de tus necesidades. Si eliges esta modalidad, la planificación nutricional te llegará en un máximo de 4 días hábiles posteriores al envío del formulario, toda la información te llegará al mismo correo que ingresaste en la página web y el formulario de atención. ¡No dudes en contactarme por cualquiera de mis vías de comunicación si tienes alguna pregunta o necesitas ayuda para completar el formulario! ¡Gracias por confiar en mi trabajo! 💜🍒",
      precio: "CLP 19.990",
      precio_descuento: "CLP 15.990",
      activo: true,
      tipo: "paciente_ficha",
    },
    {
      title: "Seguimiento/Control",
      descripcion:
        "En esta sesión nos comunicaremos por videollamada (Google meet) igual que en la primera sesión pero esta vez con una duración de 20-30 min, dónde la finalidad es poder resolver dudas en conjunto, monitorear los procesos de cambio de conducta, reforzar factores positivos  y ver el proceso del cumplimiento de los objetivos que establecimos en la primera sesión. Gracias por confiar en mi trabajo!💜🍍",
      tiempo: "30 minutos app.",
      precio: "CLP 17.990",
      activo: true,
      tipo: "paciente_antiguo",
    },
  ];

  const ModalInfo = (props) => {
    const [show, setShow] = useState(false);
    return (
      <div>
        <Button variant="link" className="mb-3" onClick={() => setShow(true)}>
          Descripción
        </Button>
        <Modal show={show} onHide={() => setShow(false)}>
          <Modal.Header closeButton></Modal.Header>
          <Modal.Body>
            <div className="container-fluid">{props.descripcion}</div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={() => setShow(false)}>
              Volver
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    );
  };


  return (
    <section id="section_agenda">
      <div className="container py-5">
        <div className="row gy-4 justify-content-center">
          <div className="col-12">
            <h2 className="display-5 fw-bold text-center">
              Nuestros servicios
            </h2>
          </div>

          {servicios.map((c, i) =>
            c.activo ? (
              <div key={i} className="col-lg-4 text-center p-3">
                <div className="card shadow">
                  <div className="card-body">
                    <h5 className="fw-bold mb-4">{c.title}</h5>
                    {c.subtitulo && <p>{c.subtitulo}</p>}
                    {c.descripcion && (
                      <ModalInfo
                        key={i}
                        descripcion={c.descripcion}
                      ></ModalInfo>
                    )}

                    <p>{c.tiempo}</p>

                    {c.precio_descuento ? (
                      <>
                        <p className="text-decoration-line-through">
                          {c.precio}
                        </p>
                        <p className="h5 text-success">
                          {c.precio_descuento}{" "}
                          <span className="badge rounded-pill bg-success">
                            Promoción
                          </span>
                        </p>
                      </>
                    ) : (
                      <p className="h5 text-success">{c.precio}</p>
                    )}

                    <div className="mt-5">
                      {c.tipo === "paciente_ficha" ? (
                        <ModalFicha></ModalFicha>
                      ) : (
                        <ModalAgendar tipo={c.tipo}></ModalAgendar>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              ""
            )
          )}
        </div>
      </div>
    </section>
  );
}

export default SectionAgenda;
