import React from "react";

function SectionPacientesNo(props) {
  return (
    <section id="pacientesno" className="section image-naranjas py-5">
      <div className="container py-4">
        <div className="row gy-4">
          <div className="col-12">
            <h2 className="display-5 fw-bold text-center">
              Áreas fuera de nuestro enfoque de atención
            </h2>
          </div>
          <div className="row d-flex justify-content-center p-3">
            <div className="col-lg-4 card shadow">
              <div className="card-body">
                <p className="card-text p-3">
                  Como nutricionista, mi objetivo es proporcionar un servicio de
                  calidad centrado en el bienestar de mis pacientes. Reconozco
                  mis propias limitaciones y me enfoco en casos en los que puedo
                  ofrecer un mayor nivel de conocimiento y atención
                  especializada, asegurando que las necesidades nutricionales de
                  los pacientes se satisfagan plenamente.
                </p>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="card shadow">
                <div className="card-body  text-left">
                  <h5 className="card-title p-3">
                    Áreas fuera de mi enfoque de atención
                  </h5>
                  <ul>
                    <li>Deportistas de alto rendimiento</li>
                    <li>Alergias alimentarias</li>
                    <li>Transtornos del espectro autista (TEA)</li>
                    <li>Menores de 14 años</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default SectionPacientesNo;
