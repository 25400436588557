import React from "react";
import { Accordion } from "react-bootstrap";

function SectionPreguntas(props) {
  const preguntasRespuestas = [
    {
      pregunta: "¿Cómo puedo agendar una consulta de nutrición?",
      respuesta:
        "Para agendar una consulta nutricional, puedes hacerlo a través de nuestro sitio web, en el apartado 'NUESTROS SERVICIOS' donde aparecen los distintos servicios que ofrecemos.",
    },
    {
      pregunta: "¿Qué tipo de planificaciones alimentarias ofrecen?",
      respuesta:
        "Ofrecemos planes de alimentación personalizados basados en tus necesidades y objetivos. Estos planes pueden incluir recomendaciones para mejorar tu relación con la comida, alimentación consciente, recetarios saludables y materiales digitales de regalo, los cuales serán herramientas útiles para lograr tus objetivos.",
    },
    {
      pregunta:
        "¿Cuál es la diferencia entre la consulta nutricional por videollamada y la consulta nutricional por formulario?",
      respuesta:
        "La diferencia entre ambos servicios recae en la manera en la que nos comunicamos. La opción por videollamada es una modalidad remota en vivo desde cualquiera de tus dispositivos con una duración limitada, mientras que la modalidad formulario no requiere videollamada, ya que tú mismo irás completando la información requerida para obtener una planificación personalizada.",
    },
    {
      pregunta: "¿Cuánto tiempo dura una consulta de nutrición?",
      respuesta:
        "Nuestras consultas de nutrición tienen una duración aproximada de 60 minutos por videollamada, mientras que la opción de formulario tiene un máximo de 24 horas para ser respondida y enviada. Los controles o seguimientos tienen una duración de aproximadamente 30 minutos.",
    },
    {
      pregunta:
        "¿Cuando llegue el día de mi consulta nutricional, por dónde me llegará el link para unirme a la sesión?",
      respuesta:
        "El día de la consulta nutricional, te enviaré un email (el que registraste en nuestra web) 10 minutos antes de la hora que agendaste. Si no te llega el link de la sesión, nos puedes contactar por Instagram (@Tunutriamiga_) y te haré llegar el link por ese medio.",
    },
    {
      pregunta: "¿Cómo puedo preparar comidas saludables en poco tiempo?",
      respuesta:
        "Podemos proporcionarte recetas sencillas y rápidas que sean nutritivas y deliciosas.",
    },
    {
      pregunta:
        "¿Atiendes casos especiales como embarazadas, vegetarianos, pacientes con algún tipo de cáncer?",
      respuesta:
        "Sí, pero si tienes dudas sobre qué casos no atendemos, te recomiendo revisar nuestro apartado 'ÁREAS FUERA DE NUESTRO ENFOQUE DE ATENCIÓN'.",
    },
    {
      pregunta: "¿Qué tan seguido debo tener consultas de seguimiento?",
      respuesta:
        "La frecuencia de las consultas de seguimiento dependerá de tus objetivos de salud y tu progreso individual. Al finalizar la sesión, te recomendaré una frecuencia adecuada para tus necesidades.",
    },
    {
      pregunta: "¿Qué pasa si mi pago falla?",
      respuesta:
        "Si ocurre algún problema al ingresar tu método de pago, puedes comunicarte conmigo a través de mi correo electrónico (Camila.tunutriamiga@gmail.com) o por mensaje de Instagram (@Tunutriamiga_), y así podremos ponernos en contacto para solucionar cualquier inconveniente.",
    },
  ];

  return (
    <section
      id="preguntas"
      className="image-donas"
      style={{ backgroundColor: "#BBE7FE" }}
    >
      <div className="container py-4">
        <div className="row gy-4 ">
          <div className="col-12">
            <div className="card">
              <div className="card-body">
                <h2 className="display-5 fw-bold text-center">
                  Preguntas frecuentes
                </h2>
              </div>
            </div>
          </div>
          <div className="col-12 pb-4">
            <Accordion>
              {preguntasRespuestas.map((p, i) => (
                <Accordion.Item key={i} eventKey={i}>
                  <Accordion.Header>
                    <p className="fw-bold">{p.pregunta}</p>
                  </Accordion.Header>
                  <Accordion.Body>{p.respuesta}</Accordion.Body>
                </Accordion.Item>
              ))}
            </Accordion>
          </div>
        </div>
      </div>
    </section>
  );
}

export default SectionPreguntas;
