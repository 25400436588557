// utils.js
const BASE_URL = {
  development: "http://127.0.0.1:5000",
  production: "https://www.tunutriamiga.cl",
};

export const getEnvUrl = () => BASE_URL[process.env.NODE_ENV || "development"]; // Default to 'development'

async function getJson(url, params = {}) {
  const queryString = new URLSearchParams(params).toString();
  const fullUrl = `${getEnvUrl()}${url}?${queryString}`;

  try {
    const response = await fetch(fullUrl);
    if (!response.ok) {
      throw new Error(`Network response error: ${response.status}`);
    }
    return response;
  } catch (error) {
    console.error("Error fetching data:", error);
    throw error; // Re-throw for potential handling elsewhere
  }
}

async function postJson(url, data) {
  const fullUrl = `${getEnvUrl()}${url}`;

  try {
    const response = await fetch(fullUrl, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(data),
    });

    if (!response.ok) {
      throw new Error(`Network response error: ${response.status}`);
    }
    return response;
  } catch (error) {
    console.error("Error sending data:", error);
    throw error;
  }
}

export { getJson, postJson };
