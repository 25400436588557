import React, { lazy, Suspense, useEffect } from "react";
import { Navbar, Nav } from "react-bootstrap";
import {
  Router,
  Routes,
  Route,
  Outlet,
  Link,
  NavLink,
  useLocation,
} from "react-router-dom";

//const HomePage = lazy(() => import("./pages/HomePage"));
import HomePage from "./pages/HomePage";
import AdminPage from "./pages/AdminPage";
import PrivacidadPage from "./pages/PrivacidadPage";
import TerminosPage from "./pages/TerminosPage";
import { CustomNavbar, Footer } from "./components";
import SectionSobreMi from "pages/SobreMiPage";

import ReactGA from "react-ga4";

function App() {
  const location = useLocation();
  ReactGA.initialize("G-09FTM24P6B");

  useEffect(() => {
    // Enviar el pageview con la ruta y el título actuales
    ReactGA.send({
      hitType: "pageview",
      page: location.pathname,
      title: document.title,
    });
  }, [location]);

  return (
    <div>
      <CustomNavbar></CustomNavbar>
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/admin" element={<AdminPage />} />
        <Route path="*" element={<NoMatch />} />
        <Route path="/sobremi" element={<SectionSobreMi />} />
        <Route path="/terminos" element={<TerminosPage />} />
        <Route path="/privacidad" element={<PrivacidadPage />} />
      </Routes>
      <Footer></Footer>
    </div>
  );
}

function NoMatch() {
  return (
    <div className="container py-5">
      <h2>
        Hubo un error en la página. Si el problema persiste por favor envíanos
        un mensaje
      </h2>
      <p>
        <Link to="/">Volver a la página principal</Link>
      </p>
    </div>
  );
}

export default App;
