import React from "react";
import { Button } from "react-bootstrap";
import { Link, animateScroll as scroll } from "react-scroll";

function Hero(props) {
  return (
    <section className="hero-section">
      <div className="container">
        <div className="row d-flex justify-content-start px-xl-4 px-md-3 py-4">
          <div className="col-lg-6 col-sm-8 order-2 order-lg-1 d-flex flex-column justify-content-center">
            <h1 className="display-2 fw-bold text-white">Tu Nutri Amiga</h1>
            <p className="lead font-weight-bold text-white pr-3">
              Ofrecemos planes personalizados para ayudarte a alcanzar tus
              objetivos de salud y bienestar.
            </p>
            <div className="d-flex">
              <Button
                size="lg"
                onClick={() => window.location.replace("#section_agenda")}
                className="btn_redondo btn-custom animate__animated animate__pulse animate__infinite"
              >
                Agenda tu hora
              </Button>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Hero;
