import React, { useContext, useState } from "react";
import { Navbar, Nav, Image, NavDropdown, Button } from "react-bootstrap";
import { NavLink } from "react-router-dom";
import ModalLogin from "components/ModalLogin";
import ModalSignup from "components/ModalSignup";

import logo from "../images/camiLogo.png";
import { AuthContext } from "api/AuthContextProvider";
import { tsuccess } from "tools";

const UserDropdown = () => {
  const { logout, current_user } = useContext(AuthContext);
  return (
    <NavDropdown
      align="end"
      title={
        <div
          className="avatar"
          style={{
            backgroundColor: `#ffb3c3`, //
          }}
        >
          <span className="initial">{current_user.nombre[0]}</span>
        </div>
      }
      id="basic-nav-dropdown"
    >
      <NavDropdown.Item onClick={() => tsuccess("Aún en construcción jeje.")}>
        Mi perfil
      </NavDropdown.Item>
      <NavDropdown.Divider />
      <NavDropdown.Item onClick={logout}>Cerrar sesión</NavDropdown.Item>
    </NavDropdown>
  );
};

function CustomNavbar(props) {
  const { isAuthenticated, current_user } = useContext(AuthContext);
  const [showLogin, setShowLogin] = useState(false);
  const [showSignup, setShowSignup] = useState(false);
  return (
    <div>
      <Navbar bg="white" expand="lg" className="shadow-sm rounded">
        <Navbar.Brand as={NavLink} to="/" className="px-5 ml-5">
          <Image
            src={logo}
            alt="Logo"
            height="60"
            className="d-inline-block align-top"
          />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="mx-auto">
            <Nav.Link as={NavLink} to="/">
              Inicio
            </Nav.Link>
            <Nav.Link href="#section_agenda">Servicios</Nav.Link>
            <Nav.Link as={NavLink} to="/sobremi">
              Sobre mi
            </Nav.Link>
            <Nav.Link href="#contact">Contacto</Nav.Link>
          </Nav>
          <Nav>
            {!isAuthenticated ? (
              <div>
                <Button variant="primary" onClick={() => setShowLogin(true)}>
                  Iniciar sesión
                </Button>
                <ModalLogin
                  show={showLogin}
                  handleClose={() => setShowLogin(false)}
                  handleSignup={() => setShowSignup(true)}
                ></ModalLogin>

                <Button variant="link" onClick={() => setShowSignup(true)}>
                  Crea tu cuenta
                </Button>
                <ModalSignup
                  show={showSignup}
                  handleClose={() => setShowSignup(false)}
                ></ModalSignup>
              </div>
            ) : (
              <div>
                <UserDropdown></UserDropdown>
                {current_user.nivel >= 999 ? (
                  <Nav.Link as={NavLink} to="/admin">
                    Revisar horas
                  </Nav.Link>
                ) : (
                  ""
                )}
              </div>
            )}
          </Nav>
        </Navbar.Collapse>
      </Navbar>
    </div>
  );
}

export default CustomNavbar;
